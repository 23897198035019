import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import './JournalContent.css'

import googlePlayStoreIcon from '../../assets/images/badge-android.png'
import appStoreIcon from '../../assets/images/badge-ios.png'
import smartStoreIcon from '../../assets/images/badge-smartstore.png'
import Footer from '../Footer/Footer'
import HeaderContent from '../Header/HeaderContent'
import TagManager from 'react-gtm-module'
import 'aos/dist/aos.css'
import AOS from 'aos'

const tagManagerArgs = {
  dataLayer: {
    userId: '001',
    userProject: 'project',
    page: 'journalcontent',
  },
  dataLayerName: 'PageDataLayer',
}

export default function JournalContent() {
  const { journalId } = useParams()
  const iOSUrl =
    'https://apps.apple.com/kr/app/%EC%97%98%EC%97%A0%EC%9C%A0%EC%97%98%EC%97%A0-lmulm/id6450875029'
  const aOSUrl = 'https://play.google.com/store/apps/details?id=com.lmulm.m'
  const smartUrl = 'https://smartstore.naver.com/giantshoulders/'
  TagManager.dataLayer(tagManagerArgs)

  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
    })
  }, [])
  return (
    <div>
      <HeaderContent />

      <div className="journal-image-box">
        <img
          className="journal-image"
          src={require(`../../assets/images/journal-content-${journalId}.jpg`)}
        />
      </div>
      <div className="fader"></div>
      <div class="join banner">
        <div
          data-aos="fade-up"
          data-aos-delay="10"
          data-aos-duration="1000"
          className="ui dividing header"
        >
          이 저널을 더 읽고 싶으신가요?
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="10"
          data-aos-duration="1000"
          className="content"
        >
          <p className="join-banner-text">
            LMULM 앱을 지금 바로 다운로드하시고, <br />
            콜렉터의 세계로 빠져들어 보세요!
          </p>

          <div className="appBtnDiv">
            <a
              href={iOSUrl}
              target="_blank"
              rel="noreferrer"
              className="appBtn"
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              easing="ease-in"
            >
              <img src={appStoreIcon} width={200} alt="appstore" />
            </a>
            <a
              href={aOSUrl}
              target="_blank"
              rel="noreferrer"
              className="appBtn"
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <img src={googlePlayStoreIcon} width={200} alt="android" />
            </a>
            <a
              href={smartUrl}
              target="_blank"
              rel="noreferrer"
              className="appBtn"
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              easing="ease-in"
            >
              <img src={smartStoreIcon} width={200} alt="appstore" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

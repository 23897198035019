import React from 'react'

function AppleAssociation() {
  return (
    //path to apple app site association file
    <>
      <link
        rel="apple-app-site-association"
        href="%PUBLIC_URL%/apple-app-site-association"
      />
    </>
  )
}

export default AppleAssociation

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TagManager from 'react-gtm-module'

const GoogleTagManager = ({ gtmId }) => {
  useEffect(() => {
    TagManager.initialize({ gtmId })
  }, [gtmId])

  return <></>
}

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
}

export default GoogleTagManager

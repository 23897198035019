import React from 'react'
// import opener from "../../assets/images/opener.png";
import styled from 'styled-components'
import slogan from '../../assets/images/lmulm-slogon-w.png'

export default function Opener() {
  return (
    <Main id="opener">
      <Container className="container">
        <Heading
          data-aos="fade-in"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="1000"
          data-aos-duration="370"
        >
          <Slogan src={slogan} alt="LMULM" />
        </Heading>
      </Container>
    </Main>
  )
}

const Main = styled.main`
  background: #9b8a79;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  color: white;
  text-align: center;
`
const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const Heading = styled.h1`
  text-transform: uppercase;
  font-weight: normal;
`

const Slogan = styled.img`
  margin: 0 auto;
  width: 55%;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
  }
`

import React from 'react'
import { Container } from 'react-bootstrap'
import './AppLink.css'
//import { GooglePlayStoreIcon, AppStoreIcon } from "../Icons";
// import {ReactComponent as GooglePlayStoreIcon} from '../../assets/images/badge-android.svg';
// import {ReactComponent as AppStoreIcon} from '../../assets/images/badge-ios.svg';
import googlePlayStoreIcon from '../../assets/images/badge-android.png'
import appStoreIcon from '../../assets/images/badge-ios.png'
import smartStoreIcon from '../../assets/images/badge-smartstore.png'

export default function AppLink() {
  const iOSUrl =
    'https://apps.apple.com/kr/app/%EC%97%98%EC%97%A0%EC%9C%A0%EC%97%98%EC%97%A0-lmulm/id6450875029'
  const aOSUrl = 'https://play.google.com/store/apps/details?id=com.lmulm.m'
  const smartUrl = 'https://smartstore.naver.com/giantshoulders/'
  return (
    <section id="applink">
      <Container className="container">
        <h1
          className="applinkTitle"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-delay="10"
          data-aos-duration="320"
        >
          지금 바로 다운로드하세요!
        </h1>
        <div className="appBtnDiv">
          <a
            href={iOSUrl}
            target="_blank"
            rel="noreferrer"
            className="appBtn"
            data-aos="slide-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="20"
            data-aos-duration="1000"
            easing="ease-in"
          >
            <img src={appStoreIcon} width={200} alt="appstore" />
          </a>
          <a
            href={aOSUrl}
            target="_blank"
            rel="noreferrer"
            className="appBtn"
            data-aos="slide-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="20"
            data-aos-duration="1000"
            easing="ease-in"
          >
            <img src={googlePlayStoreIcon} width={200} alt="android" />
          </a>
          <a
            href={smartUrl}
            target="_blank"
            rel="noreferrer"
            className="appBtn"
            data-aos="slide-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="20"
            data-aos-duration="1000"
            easing="ease-in"
          >
            <img src={smartStoreIcon} width={200} alt="appstore" />
          </a>
        </div>
      </Container>
    </section>
  )
}

import React, { useState } from 'react'

import './Header.css'
import { HashLink as Link } from 'react-router-hash-link'
import { Navbar, Nav, Container } from 'react-bootstrap'
import logo from '../../assets/images/lmulm-bi-logo-w.png'

export default function HeaderContent() {
  const [expanded, setExpanded] = useState(false)
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed="top"
      className="navbar"
      expanded={expanded}
    >
      <Container>
        <Link className="link" smooth to="/home">
          <img
            src={logo}
            alt="LMULM"
            data-aos="fade-right"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="500"
            data-aos-duration="1000"
            width={100}
          />
        </Link>
        {/* <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Link
              smooth
              to="/JournalBanner#main-next-note"
              className="link"
              onClick={() => setExpanded(false)}
            >
              Journal
            </Link>
            <Link
              smooth
              to="/Importance#importance"
              className="link"
              onClick={() => setExpanded(false)}
            >
              Concepts
            </Link>
            <Link
              className="link"
              smooth
              to="/Limitedrun#limitedrun"
              onClick={() => setExpanded(false)}
            >
              Limited Run
            </Link>
            <Link
              className="link"
              smooth
              to="/Mission#mission"
              onClick={() => setExpanded(false)}
            >
              Mission
            </Link>
            <Link
              className="link"
              smooth
              to="/Collector#collector"
              onClick={() => setExpanded(false)}
            >
              Collectors
            </Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}

import * as React from 'react'
import {
  CompanyInfo,
  CompanyInfoSpan,
  FooterContainer,
  NewsletterH6,
  NewsletterMenu,
  RightSiteMenu,
  SiteFooter,
  SiteInfo,
  SiteInfoLink,
  SocialNavigation,
  SocialNavigationUl,
  SocialNavigationLi,
  SocialNavigationLink,
  Spacer,
  NewsletterPolicyH6,
} from './style'
import InstagramIcon from '@mui/icons-material/Instagram'
import logoImg from '../../assets/images/lmulm-bi-logo-w.png'

function Footer() {
  return (
    <SiteFooter>
      <FooterContainer>
        <SiteInfo>
          <SiteInfoLink />
          <CompanyInfo>
            <CompanyInfoSpan>
              {/* <LogoSpan>우리</LogoSpan>의 비전은 */}
              우리의 비전은
            </CompanyInfoSpan>
            <CompanyInfoSpan>
              의미 없이 버려지는 소비의 시대에서
            </CompanyInfoSpan>
            <CompanyInfoSpan>
              한정된 상품, 콘텐츠, 공간, 경험의 무한한 가치를 찾아
            </CompanyInfoSpan>
            <CompanyInfoSpan>
              의미있는 만듦의 시대로의 변화를 주도하는것 입니다.
            </CompanyInfoSpan>
            <CompanyInfoSpan>그래서 우리는</CompanyInfoSpan>
            <CompanyInfoSpan>
              고객의 소비에 무한한 가치를 줄 수 있는 콘텐츠를 생산하고 소비로
              연결하여
            </CompanyInfoSpan>
            <CompanyInfoSpan>
              만드는 이들의 지지를 받을 수 있는 콘텐츠커머스 사업자가 되고자
              합니다.
            </CompanyInfoSpan>
            <CompanyInfoSpan>
              우리는 고객의 소비에 무한한 가치 추구하는, 만드는 이들에게 지지
              받는 콘텐츠커머스 사업자 입니다.
            </CompanyInfoSpan>
            <CompanyInfoSpan />
            <CompanyInfoSpan>
              COPYRIGHT 2023. 거인의어깨. All rights reserved.
            </CompanyInfoSpan>
          </CompanyInfo>
          <RightSiteMenu>
            <NewsletterMenu>
              <img src={logoImg} width={60} />

              {/* <NewsletterLink>문의하기&emsp;Mail Us</NewsletterLink> */}
              <Spacer />
              <SocialNavigation>
                <SocialNavigationUl>
                  {/* <SocialNavigationLi>
                    <SocialNavigationLink
                      href="https://brunch.co.kr/@e690549fed2e49a"
                      target="_blank"
                    >
                      <LinkImgBrunch />
                    </SocialNavigationLink>
                  </SocialNavigationLi> */}
                  <SocialNavigationLi>
                    <SocialNavigationLink
                      href="https://www.instagram.com/giantshoulders_official"
                      target="_blank"
                    >
                      <InstagramIcon sx={{ color: 'white' }} />
                    </SocialNavigationLink>
                  </SocialNavigationLi>
                  {/* <SocialNavigationLi>
                    <SocialNavigationLink>
                      <YouTubeIcon sx={{ color: "white" }} />
                    </SocialNavigationLink>
                  </SocialNavigationLi> */}
                </SocialNavigationUl>
              </SocialNavigation>
              <NewsletterH6>주식회사 거인의어깨</NewsletterH6>
              <NewsletterH6>대표자 : 조형우</NewsletterH6>
              <NewsletterH6>사업자등록번호 : 170-86-03222</NewsletterH6>
              <NewsletterH6>
                경기도 안양시 동안구 시민대로327번길 11-41,
                <br /> 3층 3427호(관양동, 안양창업지원센터)
              </NewsletterH6>
              <NewsletterPolicyH6
                to="/terms-of-policy"
                target="_blank"
                key={'policy'}
                underline="none"
              >
                이용약관
              </NewsletterPolicyH6>
              <NewsletterPolicyH6
                to="/personal-policy"
                target="_blank"
                key={'policy'}
                underline="none"
              >
                개인정보처리방침
              </NewsletterPolicyH6>
            </NewsletterMenu>
          </RightSiteMenu>
        </SiteInfo>
      </FooterContainer>
    </SiteFooter>
  )
}

export default Footer

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home/Home'
import 'bootstrap'
import JournalContent from './components/JournalContent/JournalContent'
import ScrollToTop from './components/Utils/ScrollToTop'
import GoogleTagManager from './components/Utils/GoogleTagManager'
import PersonalInfoPolicy from './Pages/Terms/PersonalInfoPolicy'
import TermsOfPolicy from './Pages/Terms/TermsOfPolicy'
// import AppleAssociation from './components/AppleAssociation/AppleAssociation'

function App() {
  return (
    <div className="App">
      <GoogleTagManager gtmId="GTM-KTXW27L" />
      {/* <AppleAssociation /> */}
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/:loaded" element={<Home />} />
          <Route
            path="journal-content/:writerNm/:journalId"
            element={<JournalContent />}
          />
          <Route path="/personal-policy" element={<PersonalInfoPolicy />} />
          <Route path="/terms-of-policy" element={<TermsOfPolicy />} />
          {/* <Route
            path="/apple-app-site-association"
            element={<AppleAssociation />}
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App

import React from 'react'
import './JournalBanner.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import journal01 from '../../assets/images/journal-01.jpg'
import journal02 from '../../assets/images/journal-02.jpg'
import journal03 from '../../assets/images/journal-03.jpg'
import journal04 from '../../assets/images/journal-04.jpg'
import journal05 from '../../assets/images/journal-05.jpg'
import journal06 from '../../assets/images/journal-06.jpg'
import journal07 from '../../assets/images/journal-07.jpg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-coverflow'
// import required modules
import { EffectCoverflow, Pagination } from 'swiper'

export default function JournalBanner() {
  return (
    <div id="main-next-note" class="container-ratio container-margin-bottom">
      <div class="section-heading">
        <h3>
          <strong class="section-heading-margin-right">Journal</strong>
          <small>
            엘엠유엘엠(LMULM)이 엄선한 콜렉터들의 깊이 있는 저널을 만나보세요
          </small>
        </h3>
      </div>
      <Swiper
        effect={'coverflow'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 10,
          slideShadows: false,
        }}
        slidesPerView={2}
        spaceBetween={15}
        centeredSlides={false}
        loop={true}
        grabCursor={true}
        // pagination={{
        //   dynamicBullets: true,
        //   clickable: true,
        // }}
        modules={[EffectCoverflow, Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <Link
            to="/journal-content/정다혜/01"
            key={'01'}
            className="today-note-item-wrapper"
            underline="none"
          >
            <div class="journal__card swiper-slide">
              <img src={journal01} alt="" class="journal__img" />
              <div class="journal__data">
                <h2 class="journal__title">마음에 계속 남는</h2>
                <h2 class="journal__title">선물이 있으신가요?</h2>
                <span class="journal__description">미도리 MD노트</span>
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/journal-content/신상훈/02"
            key={'02'}
            className="today-note-item-wrapper"
            underline="none"
          >
            <div class="journal__card swiper-slide">
              <img src={journal02} alt="" class="journal__img" />
              <div class="journal__data">
                <h2 class="journal__title">단연코, 이태리 최고의</h2>
                <h2 class="journal__title">만년필 브랜드</h2>
                <span class="journal__description">오로라</span>
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/journal-content/김봉현/03"
            key={'03'}
            className="today-note-item-wrapper"
            underline="none"
          >
            <div class="journal__card swiper-slide">
              <img src={journal03} alt="" class="journal__img" />
              <div class="journal__data">
                <h2 class="journal__title">유니볼원F</h2>
                <h2 class="journal__title">바람직한 진화의 결정체</h2>
                <span class="journal__description">미쓰비시</span>
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/journal-content/김봉현/04"
            key={'04'}
            className="today-note-item-wrapper"
            underline="none"
          >
            <div class="journal__card swiper-slide">
              <img src={journal04} alt="" class="journal__img" />
              <div class="journal__data">
                <h2 class="journal__title">bLen</h2>
                <h2 class="journal__title">제브라의 구원투수</h2>
                <span class="journal__description">제브라</span>
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/journal-content/신상훈/05"
            key={'05'}
            className="today-note-item-wrapper"
            underline="none"
          >
            <div class="journal__card swiper-slide">
              <img src={journal05} alt="" class="journal__img" />
              <div class="journal__data">
                <h2 class="journal__title">대중적이지만</h2>
                <h2 class="journal__title">또 매력적인</h2>
                <span class="journal__description">워터맨 익스퍼트</span>
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/journal-content/임지혁/06"
            key={'06'}
            className="today-note-item-wrapper"
            underline="none"
          >
            <div class="journal__card swiper-slide">
              <img src={journal06} alt="" class="journal__img" />
              <div class="journal__data">
                <h2 class="journal__title">M605 블랙 토토이즈를</h2>
                <h2 class="journal__title">바라보면서</h2>
                <span class="journal__description">펠리칸</span>
              </div>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/journal-content/김봉현/07"
            key={'07'}
            className="today-note-item-wrapper"
            underline="none"
          >
            <div class="journal__card swiper-slide">
              <img src={journal07} alt="" class="journal__img" />
              <div class="journal__data">
                <h2 class="journal__title">FX153</h2>
                <h2 class="journal__title">153볼펜의 진화</h2>
                <span class="journal__description">모나미</span>
              </div>
            </div>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

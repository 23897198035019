import React from 'react'
import './PersonalInfoPolicy.css'

export default function PersonalInfoPolicy() {
  return (
    <div class="privacy_policy_terms">
      <h1>개인정보처리방침</h1>
      <p>
        거인의어깨가 운영하는 ‘LMULM서비스’(이하 ‘회사’)는 취급하는 모든
        개인정보에 대해 개인정보 보호법 등 관련 법령상의 개인정보보호규정을
        준수하여 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한
        이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고
        있습니다.
      </p>
      <ol class="index_num">
        <li>이용자 및 법정대리인의 권리/의무 및 그 행사방법</li>
        <li>개인정보 보호책임자 성명 및 부서∙연락처</li>
        <li>개인정보의 자동수집 장치 설치∙운영∙거부 사항</li>
        <li>개인정보 수집∙이용 목적, 수집하는 개인정보 항목 및 수집 방법</li>
        <li>제3자 제공 현황</li>
        <li>보유 및 이용 기간, 파기 절차 및 파기 방법</li>
        <li>처리위탁 현황</li>
        <li>개인정보의 기술적/관리적 보호 대책</li>
        <li>광고성 정보 전송</li>
        <li>링크사이트</li>
        <li>스마트폰 앱 관리</li>
        <li>고지의 의무</li>
      </ol>
      <h2>제1조 이용자 및 법정대리인의 권리/의무 및 그 행사방법</h2>
      <p>
        ① 이용자는 언제든지 자신의 개인정보를 조회하고 수정할 수 있으며,
        개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방할 수
        있도록 협조해야 합니다. 부정확한 정보 제공으로 발생하는 사고의 책임은
        이용자에게 있으며, 타인 정보의 도용 등 허위 정보를 입력할 경우 회원 자격
        상실과 함께 개인정보에 관한 법률에 의거하여 처벌될 수 있습니다.
      </p>
      <p>
        ② 만14세 미만 아동은 법정대리인의 동의가 있어야 이용이 가능하며,
        법정대리인은 아동의 개인정보를 조회하거나 수정할 권리, 수집 및 이용
        동의를 철회할 권리를 가집니다.
      </p>
      <p>
        ③ 이용자 및 법정대리인은 언제든지 개인정보 열람∙정정∙삭제∙처리정지에
        대한 개인정보 보호 관련 권리를 언제든지 행사할 수 있으며, 회사는 이에
        대해 지체없이 조치하겠습니다. 또한, 접수 이후 정정 또는 삭제 처리를
        완료할 때까지 해당 개인정보를 이용하거나 제공하지 않으며 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 없이 통지하여 정정이 이루어지도록 하겠습니다.
      </p>
      <ol>
        <li>요청 방법</li>
        <ul>
          <li>회사 고객센터를 통한 전화/서면/이메일/팩스 접수</li>
          <li>회사 홈페이지에 접속하여 1:1 문의를 통한 접수</li>
          <li>
            개인정보 보호책임자 및 담당자에게 이메일 접수 (개인정보 처리방침
            제2조)
          </li>
        </ul>

        <li>요청 양식</li>
        <ul>
          <li>
            양식1.{' '}
            <a
              href="http://image.kyobobook.co.kr/ink/cscenter/docs/Kyobobook_MemInfo_Request.doc"
              download="filename"
            >
              개인정보(정정∙삭제) 요구서 [다운받기]
            </a>
          </li>
        </ul>
        <li>요청할 수 있는 사항</li>
        <ul>
          <li>회사가 보유하고 있는 이용자의 개인정보</li>
          <li>회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 현황</li>
          <li>이용자가 동의한 개인정보의 수집∙이용∙제공 현황</li>
        </ul>
      </ol>
      <p>
        ④ 제③항에 따른 권리 행사는 이용자 및 법정대리인, 위임을 받은 자 등
        대리인을 통하여 하실 수 있습니다. 단, 대리인이 권리 행사를 요구 할 경우
        위임장을 작성하여 제출하셔야 합니다.
      </p>
      <ul>
        <li>
          양식2.{' '}
          <a
            href="http://image.kyobobook.co.kr/ink/cscenter/docs/Kyobobook_MemInfo_Request.doc"
            download="filename"
          >
            위임장 [다운받기]
          </a>
        </li>
      </ul>
      <p>
        ⑤ 회사는 제③항에 따라 해지 또는 삭제한 개인정보에 대해 제6조 보유 및
        이용기간, 파기 절차 및 파기 방법에 명시된 바에 따라 처리하고 있습니다.
      </p>
      <p>
        ⑥ 이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의
        정보를 침해하지 않을 의무도 가지고 있습니다. 개인정보가 유출되지 않도록
        조심하시고 타인의 게시물 및 개인정보를 훼손하지 않도록 유의하시기
        바랍니다.
      </p>
      <p>
        ⑦ 회사는 이용자가 타인의 개인정보를 도용하여 회원가입 등을 하였음을 알게
        된 때에는 지체없이 해당 아이디에 대한 서비스 이용정지 또는 회원탈퇴 등
        필요한 조치를 취합니다. 또한 자신의 개인정보 도용을 인지한 이용자가 해당
        아이디에 대해 서비스 이용정지 또는 회원탈퇴를 요구하는 경우에는 확인
        완료 후 즉시 조치를 취합니다.
      </p>
      <h2>제2조 개인정보 보호책임자 성명 및 부서∙연락처</h2>
      <p>
        ① 이용자의 개인정보를 보호하고 개인정보와 관련한 불만 처리 및 피해구제
        등을 위하여 회사는 개인정보 보호책임자를 지정하고 있습니다.
      </p>
      <table>
        <tbody>
          <tr>
            <td class="t_bg">직무</td>
            <td>개인정보보호책임자</td>
          </tr>
          <tr>
            <td class="t_bg">책임자</td>
            <td>조형우</td>
          </tr>
          <tr>
            <td class="t_bg">연락처</td>
            <td>010-7614-0162</td>
          </tr>
          <tr>
            <td class="t_bg">이메일</td>
            <td>lmulm.cs@gmail.com</td>
          </tr>
        </tbody>
      </table>
      <p>
        ② 회사가 제공하는 서비스를 이용하시면서 발생한 개인정보 보호 관련 문의,
        불만처리, 피해구제 등에 관한 사항은 개인정보 보호책임자, 개인정보 보호
        관리자 및 고객센터로 문의하실 수 있으며, 회사는 이용자들의 문의사항에
        대해 신속하고 충분한 답변을 드리도록 하겠습니다.
      </p>
      <p>
        ③ 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시면 도움을 받으실 수 있습니다.
      </p>
      <ul>
        <li>
          개인정보침해신고센터{' '}
          <a href="https://privacy.kisa.or.kr/">https://privacy.kisa.or.kr/</a>{' '}
          (국번없이) 118
        </li>
        <li>
          개인정보분쟁조정위원회{' '}
          <a href="https://www.kopico.go.kr/">https://www.kopico.go.kr/</a>{' '}
          1833-6972
        </li>
        <li>
          대검찰청 사이버수사과{' '}
          <a href="https://www.spo.go.kr/">https://www.spo.go.kr/</a> (국번없이)
          1301
        </li>
        <li>
          경찰청 사이버범죄 신고시스템{' '}
          <a href="https://ecrm.cyber.go.kr/">https://ecrm.cyber.go.kr/</a>{' '}
          (국번없이) 182
        </li>
      </ul>

      <h2>제3조 개인정보의 자동수집 장치 설치∙운영∙거부 사항</h2>
      <p>
        ① 회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의
        정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다.
      </p>
      <ul class="box_line">
        <p>쿠키(cookie)란?</p>
        <li>
          웹사이트가 이용자의 컴퓨터 브라우저(인터넷익스플로러, 크롬 등)로
          전송하는 소량의 전송하는 소량의 정보로서 이용자가 웹사이트에
          로그인(log-in)하면 서버는 이용자의 브라우저에 있는 쿠키의 내용을 읽고,
          이용자의 추가정보를 이용자 본인의 컴퓨터에서 찾아 서비스를 제공하게
          됩니다. 이러한 쿠키 정보는 이용자께 유용하고 보다 편리한 맞춤 서비스를
          제공하는데 사용되게 됩니다.
        </li>
      </ul>
      <p>
        ② 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게
        보내는 아주 작은 텍스트 파일로 이용자가 이용하는
        단말기(PC/노트북/스마트폰/태블릿PC 등)의 저장소에 저장됩니다. 이후
        이용자가 웹사이트에 방문할 경우 웹사이트 서버는 이용자가 이용하는
        단말기의 저장소에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을
        유지하기 위해 이용 됩니다.
      </p>
      <p>
        ③ 쿠키는 브라우저의 종료 시나 로그아웃 시 만료됩니다.
        <br />
        (쿠키정보는 컴퓨터를 식별할 수는 있지만 이용자 개인을 식별하지는
        않습니다.)
      </p>
      <p>
        ④ 이용자는 언제든지 쿠키의 설치를 거부하거나 삭제할 수 있습니다. 이에
        따라 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나,
        쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할
        수도 있습니다. 단, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부
        서비스는 이용에 어려움이 있을 수 있습니다. 쿠키 설정 거부 방법은 다음과
        같습니다.
      </p>
      <ol>
        <li>
          Internet Explorer의 경우
          <ul>
            <li>
              웹브라우저 상단의 [도구] 메뉴 &gt; [인터넷 옵션] &gt; [개인정보]
              탭 &gt; 고급 &gt; 직접 설정
            </li>
          </ul>
        </li>
        <li>Chrome의 경우</li>
        <ul>
          <li>
            웹 브라우저 우측 상단의 맞춤 설정 및 제어 &gt; [설정] &gt; 화면
            하단의 [고급] &gt; 하단의 [고급] &gt; 개인정보 섹션의 [콘텐츠 설정]
            &gt; [쿠키] 메뉴 직접 설정
          </li>
        </ul>

        <li>
          Safari의 경우
          <ul>
            <li>
              Safari &gt; 환경설정 &gt; 개인정보보호 &gt; 크로스 사이트 추적
              방지 or 모든 쿠키 차단
            </li>
          </ul>
        </li>
        <li>
          Firefox의 경우
          <ul>
            <li>
              메뉴 &gt; 설정 &gt; 개인정보 및 보안 (Privacy&amp;Security) &gt;
              쿠키 및 사이트 데이터 세션 &gt; 데이터 관리 &gt; 삭제 및 저장
            </li>
          </ul>
        </li>
      </ol>

      <h2>
        제4조 개인정보 수집∙이용 목적, 수집하는 개인정보 항목 및 수집 방법
      </h2>
      <p>① 회사는 서비스의 제공을 위해 개인정보를 수집하고 있습니다.</p>
      <ol>
        <li>개인회원</li>
        <table>
          <thead>
            <tr>
              <th>필수 수집 항목</th>
              <th>선택 수집 항목</th>
              <th>수집목적</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>이메일주소, 비밀번호</td>
              <td>프로필 정보, 닉네임</td>
              <td>
                회원가입, 회원관리,
                <br />
                <br />
                각종 서비스 제공
              </td>
              <td>
                서비스 거부 시까지
                <br />
                (단, 관계법령에 따름)
              </td>
            </tr>
          </tbody>
        </table>
        <li>추가 수집 유형</li>
        <table>
          <thead>
            <tr>
              <th>구분</th>
              <th>수집 항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>서비스 이용 시 자동 생성 정보</td>
              <td>
                IP Address, 쿠키, 방문 일시, OS종류, 브라우져 종류, 서비스 이용
                기록,
                <br />
                불량 이용 기록, 단말기 모델, 이동통신사 정보, 하드웨어 ID,
                단말기 OS
                <br />
                <br />
                종류, IMEI, 회원번호, PC 모바일 여부, 연령, 성별, 검색키워드,
                시간
              </td>
            </tr>
            <tr>
              <td>맞춤식 서비스 및 이벤트 응모 시</td>
              <td>개인정보 추가 수집∙이용에 대한 안내 후 수집</td>
            </tr>
            <tr>
              <td>고객센터</td>
              <td>
                고객센터 1:1문의 : 이름, 아이디, 연락처(휴대폰 or 전화번호),
                이메일, 답변 알림요청
              </td>
            </tr>
            <tr>
              <td>모바일 앱 서비스 이용 시</td>
              <td>
                목적에 따라 이동통신단말기 내 정보 및 기능에 접근하여 이용
                <br />
                <br />
                - 필수 접근 항목 : 앱 설치시 또는 최초 실행 시 안내 및 동의
                <br />- 선택 접근 항목 : 서비스 최초 이용 시 별도 동의
              </td>
            </tr>
          </tbody>
        </table>
      </ol>
      <p>
        ② 회사는 이용자가 제공한 모든 정보를 상기 목적에 필요한 용도 이외로는
        사용하지 않으며, 이용 목적이 변경될 시에는 사전에 별도 동의를 구할
        것입니다.
      </p>

      <h2>제5조 제3자 제공 현황</h2>
      <p>
        ① 회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다.
      </p>
      <p>
        ② 회사는 개인정보를 제3자에게 제공하겠다는 것에 동의한 이용자에 한하여
        아래와 같이 개인정보를 제공하며, 이용자는 제공을 거부할 수 있는 권리가
        있으나 거부 시에는 제공하는 혜택에 제한이 있을 수 있습니다.
      </p>

      <ol>
        <li>이용자가 사전에 동의한 경우</li>
        <li>
          제휴사의 서비스를 이용하기 위해서 개인정보 제공에 직접 동의를 한 경우
        </li>
        <table>
          <thead>
            <tr>
              <th>제공 받는자</th>
              <th>제공 목적</th>
              <th>제공 항목</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Apple</td>
              <td>서비스 로그인</td>
              <td>애플 아이디, 이름, 이메일</td>
              <td>
                회원탈퇴시 까지
                <br />
                <br />
                (단, 관계법령에 따름)
              </td>
            </tr>
            <tr>
              <td>Google</td>
              <td>서비스 로그인</td>
              <td>구글 아이디, 이름, 이메일</td>
              <td>
                회원탈퇴시 까지
                <br />
                <br />
                (단, 관계법령에 따름)
              </td>
            </tr>
            <tr>
              <td>네이버</td>
              <td>서비스 로그인</td>
              <td>네이버 아이디, 이름, 이메일</td>
              <td>
                회원탈퇴시 까지
                <br />
                <br />
                (단, 관계법령에 따름)
              </td>
            </tr>
            <tr>
              <td>카카오</td>
              <td>서비스 로그인</td>
              <td>카카오 아이디, 이름, 이메일</td>
              <td>
                회원탈퇴시 까지
                <br />
                <br />
                (단, 관계법령에 따름)
              </td>
            </tr>
          </tbody>
        </table>
        <li>서비스 필요 시점에 개별적 동의를 진행 한 경우</li>
      </ol>
      <p>
        ③ 다만, 다음 사항은 예외적으로 동의 없이 개인정보 제공을 할 수 있습니다.
      </p>
      <ol>
        <li>
          이용자가 외부 제휴사의 서비스를 이용하기 위하여 해당 제휴사에서
          개인정보 제공에 직접 동의를 한 경우
        </li>
        <li>
          법령에 정해진 의무 또는 수사목적으로 법령에서 정해진 절차와 방법에
          따라 수사기관의 따라 수사기관의 정당한 요청이 있는 경우
        </li>
        <li>
          이용자 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
          주소불명 등으로 사전 주소불명 등으로 사전 동의를 받을 수 없는
          경우로서, 명백히 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을
          위하여 필요하다고 인정되는 경우
        </li>
      </ol>
      <h2>제6조 보유 및 이용 기간, 파기 절차 및 파기 방법</h2>
      <p>
        이용자의 개인정보는 원칙적으로 이용목적 달성 시 지체없이 파기합니다. 단,
        다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
      </p>
      <p>① 회사 내부 방침에 의한 정보보유 사유</p>
      <ul>
        <li>보존 항목 : 아이디(ID), 이메일주소</li>
        <li>
          보존 근거 : 서비스 이용의 혼선 방지, 불법적 사용자에 대한 관련 기관
          수사 협조
        </li>
        <li>보존 기간 : 1년</li>
      </ul>
      <p>② 관계 법령에 의한 정보보유 사유</p>
      <p>
        ‘상법’, ‘전자상거래 등에서의 소비자보호에 관한 법률’ 등 관계 법령의
        규정에 의하여 보존할 필요가 있는 경우 관계 법령에서 정한 일정한 기간
        동안 개인정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
        목적으로만 이용하며 보존 기간은 아래와 같습니다.
      </p>
      <ol>
        <li>
          소비자의 불만 또는 분쟁처리에 관한 기록
          <ul>
            <li>보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
            <li>보존 기간 : 3년</li>
          </ul>
        </li>
        <li>
          웹사이트 방문기록
          <ul>
            <li>보존 근거 : 통신비밀보호법</li>
            <li>보존 기간 : 3개월</li>
          </ul>
        </li>
      </ol>
      <p>③ 파기절차</p>
      <p>
        서비스 이용을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져
        제②항에 따라 일정기간 저장된 후 파기 됩니다. 단, 일시적 목적(설문,
        이벤트 등)을 위하여 수집한 경우는 일시적 목적 달성이 완료된 시점에 즉시
        파기 합니다.
      </p>
      <p>④ 파기방법</p>
      <ol>
        <li>종이에 출력된 개인정보는 파쇄 방법으로 완전 파기합니다.</li>
        <li>
          전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
          방법(재사용 시 덮어 방법(재사용 시 덮어 쓰기, 로우(low) 포맷 등) 또는
          천공 등을 사용하여 삭제합니다.
        </li>
      </ol>
      <p>⑤ 개인정보 유효기간제 도입</p>
      <ol>
        <li>
          회사는 1년 이상 서비스 미이용자의 개인정보를 2015년 8월 이후 다른
          이용자의 개인정보와 이용자의 개인정보와 분리하여 저장∙관리 합니다. 단,
          정보통신서비스제공자와 이용자가 계약으로 별도의 보존기간을 정한 경우는
          1년이 경과 하더라도 보유 할 수 있습니다.
        </li>
        <li>
          서비스 미이용자의 산정 기준은 최종 로그인 일자, 통합포인트 or 마일리지
          사용, 사용, 통합포인트 적립, 구매 이력, 최종 상담일자로 산정하며, 복원
          및 회원탈퇴 요청이 없을 경우 서비스 미이용자에 대하여 분리하여
          저장∙관리 합니다.
        </li>
        <li>
          회사는 서비스 미이용자의 개인정보 분리∙저장∙관리 시점 도래 30일 전,
          별도 분리∙보관되는 별도 분리∙보관되는 사실 및 예정일, 별도
          분리∙보관하는 개인정보의 항목을 이메일 등을 통해 해당 이용자에게
          공지합니다.
        </li>
        <li>
          별도 분리∙보관되는 서비스 미이용자의 경우 고객센터를 통해 본인 확인 후
          또는 또는 홈페이지에서 인증(복원) 후 재 이용을 하실 수 있습니다. 단,
          분리∙보관 후 4년 내 재 이용 요청을 하지 않을 경우 회원의 개인정보를
          파기(탈퇴 처리) 하고 회원자격을 상실 시킬 수 있습니다.
        </li>
      </ol>
      <h2>제7조 처리위탁 현황</h2>
      <p>
        ① 회사는 기본적인 서비스의 제공, 서비스의 유지/관리, 이용자 편의 제공 등
        원활한 업무 수행을 위하여 다음과 같이 개인정보 처리업무를 외부
        전문업체에 위탁 운영하고 있습니다. 회사의 개인정보 위탁처리 업체 및
        위탁업무 내용은 아래와 같습니다.
      </p>
      <table>
        <thead>
          <tr>
            <th>위탁 업체</th>
            <th>위탁 업무</th>
            <th>보유 및 이용기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Amazone Web Services
              <br />
              <br />
              (AWS KOREA)
            </td>
            <td>전산시스템 인프라 서비스 제공</td>
            <td>
              서비스 거부 시 혹은
              <br />
              위탁 계약 종료시
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        ② 회사는 업무처리를 위하여 해당 업체에 업무 수행에 필요한 최소한의
        정보만을 제공하고 있으며, 관계 법령에 따라 위탁 계약 시 개인정보가
        안전하게 관리될 수 있도록 필요한 사항들을 규정하여 위탁된 개인정보가
        위법하게 이용되지 않도록 관리∙감독 하고 있습니다.
      </p>

      <p>
        ③ 회사는 이용자의 사전 동의가 있거나, 관계 법령의 규정에 의한 경우를
        제외하고는 어떠한 경우에도 개인정보 처리위탁 안내에 명시한 범위를 넘어
        이용자 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 단, 이용자의
        문의 내용이나 문의 내용이나 불만 내용이 회사 매장 내 입점한 협력업체와의
        관계라고 판단될 경우에는 신속한 서비스를 위해 해당 협력업체에 접수
        내용을 제공할 수 있습니다.
      </p>

      <h2>제8조 개인정보의 기술적/관리적 보호 대책</h2>
      <p>
        회사는 이용자들의 개인정보를 가장 소중한 가치로 여기고 개인정보를
        처리함에 있어서 다음과 같은 노력을 다하고 있습니다.
      </p>
      <p>
        ① 이용자의 개인정보를 암호화하고 있습니다. 이용자의 개인정보를 암호화된
        통신구간을 이용하여 전송하고, 비밀번호 등 중요정보는 암호화하여 보관하고
        있습니다.
      </p>
      <ul class="box_line">
        <p>
          <strong>※ 이용자 유의 사항</strong>
        </p>
        <li>
          <p>
            첫째, 이용자 스스로도 제3자에게 비밀번호 등이 노출되지 않도록 주의
            하셔야 하며, 하셔야 하며, 특히 비밀번호 등이 공공장소에 설치한 PC를
            통해 유출되지 않도록 항상 유의하시기 바랍니다. 또한 이용자의 ID와
            비밀번호는 반드시 본인만 사용하시고 비밀번호를 자주 바꿔주시는 것이
            좋습니다.
          </p>
        </li>
        <li>
          <p>
            둘째, 이용자 본인의 부주의나 인터넷상의 문제로 개인정보가 유출되어
            발생한 문제에 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
          </p>
        </li>
        <li>
          <p>
            셋째, 그 외 내부 관리자의 실수나 기술 관리 상의 사고로 개인정보가
            분실•도난•유출•위조•변조•훼손 되었을 때에는 회사는 지체없이 해당
            이용자에게 사실을 알리고 그에 대한 적절한 대책과 보상을 마련할
            것입니다.
          </p>
        </li>
      </ul>
      <p>
        ② 해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다.
        회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나
        훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에
        대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여
        이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며,
        암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수
        있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의
        무단접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한
        가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
      </p>
      <p>
        ③ 개인정보에 접근할 수 있는 인원을 최소화하고 정기적인 교육을 실시하고
        있습니다. 있습니다. 회사는 개인정보 관련 처리 인원을 한정시키고 있으며,
        개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에
        대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인
        기준을 마련하고 지속적인 모니터링을 실시하고 있습니다. 또한, 개인정보를
        처리하는 담당자에게 정기적인 교육을 통해 회사 개인정보 처리방침의 준수를
        항상 강조하고 있습니다.
      </p>

      <h2>제9조 광고성 정보 전송</h2>
      <p>
        ① 회사는 이용자의 사전 동의 없이 영리 목적의 광고성 정보를 전송하지
        않습니다. 않습니다. 단, 재화등의 거래관계를 통하여 이용자로부터 직접
        연락처를 수집하여 처리한 경우 해당 재화등의 거래가 종료된 날부터 6개월
        이내에 동종의 재화등에 대해서는 사전 동의를 받지 않고 광고성 정보 전송이
        가능합니다.
      </p>
      <p>
        ② 회사는 신규 콘텐츠 알림이나 이벤트 정보안내 등 이용자의 지향적인
        마케팅을 수행하기 위하여 광고성 정보를 전송할 때는 이용자가 광고성
        정보임을 쉽게 알아볼 수 있도록 조치합니다.
      </p>
      <ul>
        <li>
          문자 : 광고성 정보가 시작되는 부분에 ‘(광고)’ 표시, 무료수신거부 번호
          안내
        </li>
        <li>
          이메일 : 제목이 시작되는 부분에 ‘(광고)’ 표시, 쉬운 수신거부 절차 안내
        </li>
        <li>
          앱 푸쉬 : 광고성 정보가 시작되는 부분에 ‘(광고)’ 표시, 거부절차 안내
        </li>
      </ul>
      <p>
        ③ 회사는 영리 목적의 광고성 정보 전송 시 법적 요구사항 준수를 위해
        필요한 조치(전송자 연락처, 무료수신거부 전화번호 안내 등)를 합니다.
      </p>
      <h2>제10조 링크사이트</h2>
      <p>
        ① 회사는 이용자들에게 홈페이지를 통해 다른 회사(관계사 등)의 웹사이트
        또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및
        자료에 대한 아무런 통제권이 없으므로 그것으로부터 제공받는 자료의
        유용성에 대해 책임질 수 없으며 보증할 수 없습니다.
      </p>
      <p>
        ② 회사의 홈페이지 내에 있는 링크를 클릭(click)하여 타 사이트(site)의
        페이지로 옮겨갈 경우 해당 사이트의 개인정보 처리방침은 회사와 무관하므로
        방문한 사이트의 개인정보 처리방침을 살펴 보시기 바랍니다.
      </p>
      <h2>제11조 스마트폰 앱 관리</h2>
      <p>
        ① 회사는 스마트폰 앱을 통하여 서비스를 제공 시, 이용자에게 개인정보
        수집∙이용 동의를 받은 범위 내에서 단말기 정보 등에 접근한다는 것을
        고지하고 확인을 획득한 후에 정보를 수집하거나
      </p>
      <p>
        ② 앱 상에서 이용자가 단말기 접근 권한을 허용하였다고 하여 허용권한과
        관련된 모든 정보가 즉시 수집되거나 전송되는 것은 아닙니다.
      </p>
      <p>
        ③ 서비스 제공을 위하여 스마트폰 앱의 접근 권한을 필수 또는 선택적으로
        이용자에게 요청하고 있으며, 단말기 내 “설정” 메뉴를 통하여 이용자가 직접
        권한을 변경할 수 있으며, 앱 권한에 대한 자세한 사항은 앱 스토어를 통해
        확인 하실 수 있습니다.
      </p>
      <p>
        ④ 스마트폰 상에서 앱을 삭제하더라도 이용자의 회원계정은 유지되므로,
        회원탈퇴를 원하실 경우는 고객센터(1544-1900)로 연락 바랍니다.
      </p>
      <h2>제12조 고지의 의무</h2>
      <p>
        현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
        7일전부터 홈페이지의 공지사항을 통해 고지할 것입니다.
      </p>
      <ul>
        <li>개인정보처리방침 버전번호 : V1.0</li>
        <li>공고일자 : 2023년 7월 1일</li>
        <li>시행일자 : 2023년 7월 1일</li>
      </ul>
    </div>
  )
}

import styled from '@emotion/styled'
import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export const SiteFooter = styled.div`
  color: #fff;
  background-color: #000;
  padding: 50px 15px 50px 15px;
  text-align: center;
  bottom: 0;
  @media (min-width: 769px) {
    text-align: left;
    left: 0;
    right: 0;
    height: 292px;
    padding: 50px 65px 50px 15px;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  max-width: 1680px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 421px) {
    width: calc(100% - 10px);
  }
`

export const SiteInfo = styled.div`
  font-size: 1.24rem;
  line-height: 2rem;
  width: 100%;
  @media (min-width: 769px) {
    display: flex;
    width: 100%;
  }
`

export const SiteInfoLink = styled.div`
  margin-bottom: 15px;
  @media (min-width: 769px) {
    width: 140px;
    margin-right: 14px;
    font-size: 1.1rem;
  }
`

export const CompanyInfo = styled.div`
  margin-bottom: 38px;
  font-size: 1.1rem;
  @media (min-width: 769px) {
    flex-grow: 1;
  }
`

export const CompanyInfoSpan = styled.span`
  display: block;
  font-size: 0.8rem;
  line-height: 1.3rem;
  text-align: left;
`

export const RightSiteMenu = styled.div`
  min-width: 280px;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 769px) {
    flex-direction: column;
  }
`

export const NewsletterMenu = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  @media (min-width: 769px) {
    text-align: right;
  }
`

export const NewsletterH4 = styled.h4`
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  letter-spacing: 1.25px;
  clear: both;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  @media (min-width: 769px) {
    font-size: 18px;
  }
`

export const NewsletterH6 = styled.h6`
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.3rem;
  text-align: left;
  margin: 0;
  padding: 0;
  @media (min-width: 769px) {
    font-size: 0.8rem;
  }
`

export const NewsletterPolicyH6 = styled(RouterLink)`
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.3rem;
  text-align: left;
  margin-top: 10px;
  padding: 0;
  border: none;
  text-decoration: none;
  margin-left: 10px;
  @media (min-width: 769px) {
    font-size: 0.8rem;
  }
`

export const NewsletterLink = styled(Link)`
  background-color: #fff;
  color: #000;
  font-weight: 400;
  display: inline-block;
  line-height: 36px;
  padding: 0 24px;
  border: none;
  font-size: 14px;
  text-decoration: none;
  @media (min-width: 769px) {
    font-size: 12px;
  }
`

export const Spacer = styled.div`
  flex-basis: 30px;
`

export const SocialNavigation = styled.nav`
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
  font-size: 0;
  @media (min-width: 769px) {
    text-align: right;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

export const SocialNavigationUl = styled.ul`
  display: block;
  padding: 0;
  list-style: none;
`

export const SocialNavigationLi = styled.li`
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  @media (min-width: 769px) {
    margin-left: 0;
    margin-right: 0;
  }
`

export const SocialNavigationLink = styled(Link)`
  margin-left: 5px;
  margin-right: 5px;
  @media (min-width: 769px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const LogoSpan = styled.span`
  color: #fff;
  font-size: 0.9rem;
`

export const LogoImg = styled.img`
  content: url('../../assets/images/lmulm-bi-logo-w.png');
  width: 48px;
`

export const LinkImgBrunch = styled.img`
  content: url('../../assets/images/footer-link-brunch.png');
  width: 24px;
`

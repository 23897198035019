import React from 'react'
import Countdown from 'react-countdown'
import { Link } from 'react-router-dom'
import './MainBanner.css'
// import hourglassIcon from '../../assets/images/hourglass.png'

export default function MainBanner() {
  const Completionist = () => <span>마감되었습니다.</span>
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />
    } else {
      return (
        <div id="timer" data-opened="1686322800">
          <span className="timer-item">
            {days.toLocaleString('ko-KR', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
          <span className="timer-item-devide">:</span>
          <span className="timer-item">
            {hours.toLocaleString('ko-KR', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
          <span className="timer-item-devide">:</span>
          <span className="timer-item">
            {minutes.toLocaleString('ko-KR', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
          <span className="timer-item-devide">:</span>
          <span className="timer-item">
            {seconds.toLocaleString('ko-KR', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
        </div>
      )
    }
  }
  return (
    <div id="main-content">
      <div
        id="main-today-note"
        className="container-ratio container-margin-bottom active"
      >
        <div className="today-note-link">
          <Link
            to="/journal-content/신상훈/08"
            key={'08'}
            id="today-note-img"
            className="bg-img ratio-4-3"
            underline="none"
          />
          <div id="today-note">
            {/* <div id="today-note-tag-only" className="view-today">
              <h3>
                <strong>Limited Run</strong>
              </h3>
            </div> */}
            <Link
              to="/journal-content/신상훈/08"
              key={'08'}
              className="today-note-item-wrapper"
              underline="none"
            >
              <div className="today-note-item">
                <div id="timer-container">
                  {/* <div id="timer-sub">
                    <img src={hourglassIcon} id="clock-icon" alt="clock-icon" />
                    <p id="clock-icon-sub">
                      <small>
                        이 시간이 지나면
                        <br />
                        구매할 수 없습니다.
                      </small>
                    </p>
                  </div> */}
                  <div id="timer-sub">
                    {/* <img src={hourglassIcon} id="clock-icon" alt="clock-icon" /> */}
                    <p id="clock-icon-sub">
                      <strong>Limited Run</strong>
                    </p>
                  </div>
                  <Countdown
                    // date={Date.now() + 30000000}
                    date={new Date('2024-02-29')}
                    renderer={renderer}
                    zeroPadTime={2}
                  />
                </div>
              </div>
              <div className="today-note-item">
                <p className="today-note-heading">
                  <strong>한정예약판매</strong>
                </p>
                <h2 className="today-note-title">
                  오로라
                  <br />
                  레오나르도 다빈치
                </h2>
                <h3 className="today-note-title-sub">
                  설렘 가득한 시작의 순간
                </h3>
                <p className="today-note-desc">
                  LMULM의 오픈이 얼마 남지 않은 어느 날, 앱 신규 런칭 이벤트를
                  위한 한정판 만년필 선정에 대한 조언을 구하는 연락이 왔습니다.
                  <br />
                  부담을 가득 안고 만년필을 선정하기 위해 받은 리스트를 열어보니
                  유명 브랜드들의 화려한 라인업에서도 눈을 뗄 수 없었던 펜이
                  있었으니
                  <br />
                  바로 오로라 레오나르도 다 빈치였습니다.
                  <span className="circle-punctuation"></span>
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// import Header from '../../components/Header/Header'
import Opener from '../../components/Opener/Opener'
// import GetStarted from '../../components/GetStarted/GetStarted'
import AppLink from '../../components/AppLink/AppLink'
import './Home.css'
// import Importance from '../../components/Importance/Importance'
// import Mission from '../../components/Mission/Mission'
// import Collector from '../../components/Collector/Collector'
import 'aos/dist/aos.css'
import AOS from 'aos'
import Footer from '../../components/Footer/Footer'
// import Limitedrun from '../../components/Limitedrun/Limitedrun'
import MainBanner from '../../components/MainBanner/MainBanner'
import JournalBanner from '../../components/JournalBanner/JournalBanner'
import HeaderContent from '../../components/Header/HeaderContent'
import AppleAssociation from '../../components/AppleAssociation/AppleAssociation'

export default function Home() {
  const [loading, setLoading] = useState(true)
  const { loaded } = useParams()
  useEffect(() => {
    setTimeout(() => setLoading(false), 4000)
    AOS.init({
      duration: 2000,
      once: false,
    })
    if (loaded?.length > 0) {
      setLoading(false)
    }
  }, [])
  return (
    <>
      {loading ? (
        <div>
          <Opener />
        </div>
      ) : (
        <main id="home">
          <HeaderContent />
          <AppleAssociation />
          <MainBanner />
          <JournalBanner />
          <AppLink />
          {/* <Header />
          <GetStarted /> */}
          {/* <Importance />
          <Limitedrun />
          <Mission />
          <Collector /> */}
          <Footer />
        </main>
      )}
    </>
  )
}
